import http from 'http.js';
import {format} from 'date-fns';
import config from './Mh1config';

/*
 * Types
   BookingIntegration = {
       "name": "MH1",
       "url": "",
       "bookingId": "495"
   }

   AvailabilitySummary = {
      id: "495"
      next_available: "2025-01-27T10:05:00.000+10:00"
      slot_id: "117371924"
   }
 */

export const MH1 = 'mh1';

/**
 *
 * @param availabilitySummaries:AvailabilitySummary[]
 * @param bookingIntegration:BookingIntegration
 * @returns availability:AvailabilitySummary
 */
export const getBookingAvailability = (
    availabilitySummaries,
    bookingIntegration,
) => {
    let availability = false;

    if (bookingIntegration && availabilitySummaries?.length) {
        availability =
            availabilitySummaries.find(
                (d) =>
                    d.id.toString() ===
                    bookingIntegration.bookingId.toString(),
            ) || false;

        if (availability && availability?.next_available) {
            availability.nextAvailableDate = format(
                new Date(availability.next_available),
                config.format.short,
            );
        }
    }
    return availability;
};

export class Mh1BookingApi {
    /**
     * extract bookingIntegrationId list from practices list
     * @param items:Practice[]
     * @param key:string = "mh1"
     * @returns bookingIds:[]
     */
    extractBookingIntegrationIds(items, key = MH1) {
        const bookingIds = [];
        if (items.length === 0) return bookingIds;

        items.forEach((item) => {
            if (item?.bookingIntegration) {
                if (item.bookingIntegration?.name.toLowerCase() === key) {
                    bookingIds.push(item.bookingIntegration.bookingId);
                }
            }
        });

        return bookingIds;
    }

    async fetchAvailabilitySummary(items) {
        if (!items.length) {
            return [];
        }
        return await this.callAvailabilitySummary(items);
    }

    /**
     * Call Mh1 API
     * @param practitionerIds: string[]
     * @returns availabilitySummary: AvailabilitySummary[]
     */
    async callAvailabilitySummary(practitionerIds) {
        // those values are set in feature flags (MH1_URLS)
        const mh1Url = document.body?.dataset?.mh1AvailabilityUrl;
        const mh1NoOfDays = Number(document.body?.dataset?.mh1NoOfDays);

        try {
            if (mh1Url === undefined) {
                throw `Availability API not defined`;
            }

            const response = await http.get({
                url: `${mh1Url}/${config.endpoints.availabilitySummary}`,
                headers: config.headers,
                data: {
                    practitionerIds: practitionerIds,
                    startDate: format(new Date(), config.format.isoDate),
                    noOfDays: mh1NoOfDays,
                },
            });

            let availabilitySummary = [];
            if (response?.data?.availability?.practitioners?.length) {
                availabilitySummary =
                    response.data.availability?.practitioners;
            }

            return availabilitySummary;
        } catch (error) {
            throw `${mh1Url} is not active`;
        }
    }
}
